<template>
  <section class="card">
    <div class="card-header" style="padding: 20px 24px 0 0;">
      <div class="pull-right mt-2">
        <div class="d-inline-block">
          <router-link :to="{ name: 'groupCreate' }">
            <a-button type="primary" class="ml-3" :class="$style.filledBtn" autoInsertSpaceInButton="false">作成</a-button>
          </router-link>
        </div>
      </div>
      <h2>グループ一覧</h2>
    </div>
    <div class="card-body">
      <div v-if="groupListLoading" class="text-center">
        <a-spin tip="Loading..." />
      </div>
      <a-list
        v-if="!groupListLoading"
        item-layout="horizontal"
        :data-source="groups"
        :pagination="true"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <a slot="actions">
            <router-link :to="{ name: 'groupBelongUser', params: { id: item.id }}">
              <a-button style="width: 80px;height: 36px;">ユーザー</a-button>
            </router-link>
          </a>
          <a slot="actions">
            <router-link :to="{ name: 'groupBelongVehicle', params: { id: item.id }}">
              <a-button style="width: 80px;height: 36px;">車両</a-button>
            </router-link>
          </a>
          <router-link :to="{ name: 'groupUpdate', params: { id: item.id }}">
            <a-list-item-meta
              :description="item.comment"
            >
              <p slot="title" class="list-name">{{ item.name }}</p>
            </a-list-item-meta>
          </router-link>
        </a-list-item>
      </a-list>
    </div>
  </section>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  data() {
    return {
      groupListLoading: true,
      groups: [],
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
  },
  beforeMount() {
    const result = Vue.prototype.$api.send('get', 'groups')
    result.then(response => {
      // console.log('groups list response', response)
      this.groups = response
      this.groupListLoading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': グループの取得に失敗しました。',
        })
        this.groupListLoading = false
      })
  },
}
</script>
