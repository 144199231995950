<template>
  <section class="card">
    <div class="card-header">
      <div class="pull-right mt-2">
        <div class="d-inline-block">
          <router-link :to="{ name: 'branchCreate' }">
            <a-button type="primary" class="ml-3" :class="$style.filledBtn" autoInsertSpaceInButton="false">作成</a-button>
          </router-link>
          <router-link :to="{ name: 'branchCreateBulk' }">
            <a-button type="primary" class="ml-3" :class="$style.filledBtn" autoInsertSpaceInButton="false">一括作成</a-button>
          </router-link>
        </div>
      </div>
      <h2>
        支店一覧
      </h2>
    </div>
    <div class="card-body">
      <div v-if="branchListLoading" class="text-center">
        <a-spin tip="Loading..." />
      </div>
      <a-form layout="inline">
        <a-form-item label="支店名">
          <form-multiple-select
            placeholder="支店名"
            :candidates="branches"
            style="min-width: 200px"
            @changeSelectedItems="changeSelectedBranches"
          />
        </a-form-item>
      </a-form>
      <a-list
        v-if="!branchListLoading"
        item-layout="horizontal"
        :data-source="filteredBranches"
        :pagination="true"
        :class="$style.branchList"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <router-link :to="{ name: 'branchShow', params: { id: item.id }}">
            <a-list-item-meta
              :description="item.comment"
            >
              <p slot="title" class="list-name">{{ item.name }}<small> (id: {{ item.id }})</small></p>
            </a-list-item-meta>
          </router-link>
        </a-list-item>
      </a-list>
    </div>
  </section>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import moment from 'moment'
import FormMultipleSelect from '../../../components/FormComponents/MultipleSelect'

export default {
  components: {
    FormMultipleSelect,
  },
  props: [
    'branches',
    'branchListLoading',
  ],
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
  },
  data() {
    return {
      selectedBranches: [], // 検索条件の支店
    }
  },
  computed: {
    // 検索条件によって絞り込まれた支店リスト
    filteredBranches() {
      if (this.selectedBranches.length) {
        return this.branches.filter(b => this.selectedBranches.includes(b.id))
      } else {
        return this.branches
      }
    },
  },
  methods: {
    // 検索条件 (支店) 変更時
    changeSelectedBranches(selectedBranches) {
      this.selectedBranches = selectedBranches
    },
  },
}
</script>
