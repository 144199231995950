var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { class: _vm.$style.companyName }, [
      _vm._v(_vm._s(_vm.company.name))
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("section", { staticClass: "card" }, [
          _vm._m(0),
          _c("div", { staticClass: "card-body" }, [
            _vm.companyLoading
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [_c("a-spin", { attrs: { tip: "Loading..." } })],
                  1
                )
              : _vm._e(),
            !_vm.companyLoading
              ? _c("ul", { class: _vm.$style.basicInfoList }, [
                  _c("li", [
                    _c("span", { class: _vm.$style.infoLabel }, [
                      _vm._v("名前   : ")
                    ]),
                    _c("span", { class: _vm.$style.infoValue }, [
                      _vm._v(_vm._s(_vm.company.name))
                    ])
                  ]),
                  _c("li", [
                    _c("span", { class: _vm.$style.infoLabel }, [
                      _vm._v("プラン  : ")
                    ]),
                    _c("span", { class: _vm.$style.infoValue }, [
                      _vm._v(_vm._s(_vm.plan))
                    ])
                  ]),
                  _c("li", [
                    _c("span", { class: _vm.$style.infoLabel }, [
                      _vm._v("ユーザー数の上限 : ")
                    ]),
                    _c("span", { class: _vm.$style.infoValue }, [
                      _vm._v(
                        _vm._s(
                          _vm.company.max_user
                            ? _vm.company.max_user + "人"
                            : "上限なし"
                        )
                      )
                    ])
                  ]),
                  _c("li", [
                    _c("span", { class: _vm.$style.infoLabel }, [
                      _vm._v("作成日  : ")
                    ]),
                    _c("span", { class: _vm.$style.infoValue }, [
                      _vm._v(_vm._s(_vm._f("moment")(_vm.company.created_at)))
                    ])
                  ]),
                  _c("li", [
                    _c("span", { class: _vm.$style.infoLabel }, [
                      _vm._v("更新日  : ")
                    ]),
                    _c("span", { class: _vm.$style.infoValue }, [
                      _vm._v(_vm._s(_vm._f("moment")(_vm.company.updated_at)))
                    ])
                  ])
                ])
              : _vm._e()
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "col-lg-6" },
        [
          _c("branches-list", {
            attrs: {
              branchListLoading: _vm.branchListLoading,
              branches: _vm.branches
            }
          }),
          _c("section", { staticClass: "card" }, [
            _vm._m(1),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "insurancePolicies" } } },
                    [
                      _c("a-button", { attrs: { type: "default" } }, [
                        _vm._v("一覧")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-lg-6", staticStyle: { height: "100%" } },
        [
          _vm.myRole <= 1
            ? _c("groups-list", { attrs: { company: _vm.company } })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("基本情報")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("保険証券")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }