<template>
  <div>
    <h1 :class="$style.companyName">{{company.name}}</h1>
    <div class="row">
      <div class="col-lg-12">
        <section class="card">
          <div class="card-header">
            <h2>基本情報</h2>
          </div>
          <div class="card-body">
            <div v-if="companyLoading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <ul v-if="!companyLoading" :class="$style.basicInfoList">
              <li><span :class="$style.infoLabel">名前   : </span><span :class="$style.infoValue">{{company.name}}</span></li>
              <li><span :class="$style.infoLabel">プラン  : </span><span :class="$style.infoValue">{{plan}}</span></li>
              <li><span :class="$style.infoLabel">ユーザー数の上限 : </span><span :class="$style.infoValue">{{ company.max_user ? company.max_user + '人' : '上限なし' }}</span></li>
              <li><span :class="$style.infoLabel">作成日  : </span><span :class="$style.infoValue">{{company.created_at | moment}}</span></li>
              <li><span :class="$style.infoLabel">更新日  : </span><span :class="$style.infoValue">{{company.updated_at | moment}}</span></li>
            </ul>
          </div>
        </section>
      </div>
      <div class="col-lg-6">
        <branches-list
          :branchListLoading="branchListLoading"
          :branches="branches"
        />
        <section class="card">
          <div class="card-header">
            <h2>保険証券</h2>
          </div>
          <div class="card-body">
            <div class="text-center">
              <router-link :to="{ name: 'insurancePolicies'}">
                <a-button type="default">一覧</a-button>
              </router-link>
            </div>
          </div>
        </section>
      </div>
      <div class="col-lg-6" style="height:100%;">
        <groups-list
            v-if="myRole <= 1"
            :company="company"
          />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import storeVue from '@/store'
import BranchesList from '@/components/ListComponents/Branches'
import GroupsList from '@/components/ListComponents/Groups'
import { enumData } from '@/services/enum'
import { objectData } from '@/services/object'

export default {
  components: {
    BranchesList,
    GroupsList,
  },
  data() {
    return {
      companyLoading: true,
      branchListLoading: true,
      plan: '',
      myRole: 3,
      company: Object.assign({}, objectData.company),
      branches: [],
    }
  },
  created() {
    this.myRole = storeVue.getters.role
  },
  beforeMount() {
    const result = Vue.prototype.$api.send('get', 'company')
    result.then(response => {
      this.company = response
      this.companyLoading = false
      console.log(response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 会社の取得に失敗しました。',
        })
        this.companyLoading = false
      })

    const branchList = Vue.prototype.$api.send('get', 'branches')
    branchList.then(response => {
      this.branches = response
      this.branchListLoading = false
      console.log('branches')
      console.log(response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
        this.branchListLoading = false
      })
  },
  watch: {
    company: function(val) {
      this.plan = enumData.companyPlan.find(item => item.value === this.company.plan).label
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
  },
}
</script>
<style lang="scss" module>
@import "../style.module.scss";
.list-name {
  font-size: 1.5rem;
}
</style>
